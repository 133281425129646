import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'

const Modal = ({ isOpen, onClose, isLarge, isIframe, children }) => {
  const [element] = useState(document.createElement('div'))
  useEffect(() => {
    const modalRoot = document.getElementById('___gatsby').parentElement
    modalRoot.appendChild(element)
    return () => {
      modalRoot.removeChild(element)
    }
  }, [])
  useEffect(() => {
    if (!isOpen || !onClose) return
    const onKeyDown = (e) => e.keyCode === 27 && onClose()
    window.document.addEventListener('keydown', onKeyDown)
    return () => window.document.removeEventListener('keydown', onKeyDown)
  }, [isOpen])
  useEffect(() => {
    if (!isOpen) return
    window.document.documentElement.classList.add('is-clipped')
    return () => {
      window.document.documentElement.classList.remove('is-clipped')
    }
  }, [isOpen])
  return createPortal(
    <div className={clsx('modal', { 'is-active': isOpen })}>
      <div className='modal-background' onClick={() => onClose && onClose()} />
      <div
        className={clsx('modal-content', {
          'is-flex': isIframe,
          'is-flex-grow-1': isIframe
        })}
        style={isLarge ? { width: 'initial' } : {}}
      >
        {children}
      </div>
      {onClose && (
        <button
          className='modal-close is-large has-background-grey'
          aria-label='close'
          onClick={() => onClose()}
        />
      )}
    </div>,
    element
  )
}

export default Modal
